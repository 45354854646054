import React, {Fragment} from 'react';
import {format, random} from '../infrastructure/util';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {AppContextProps} from '../infrastructure/react-context';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {
    AppointmentCabinetsResponse,
    AppointmentDaysResponse,
    AppointmentDoctorsResponse,
    AppointmentDto,
    AppointmentHoursResponse,
    MakeAppointmentResponse,
    serverValidations,
} from '../dto';
import {SelectField} from '../infrastructure/fields/SelectInput';
import {SelectItem} from '../infrastructure/fields/select-item';
import {DateField} from '../infrastructure/fields/DateInput';
import {TextField} from '../infrastructure/fields/TextInput';
import {TextAreaField} from '../infrastructure/fields/TextAreaInput';
import {ErrorMessages} from '../infrastructure/errors';
import {Result} from '../infrastructure/api-result';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {required} from '../infrastructure/fields/validations';

import './MakeAppointment.scss';
import {NumberField} from '../infrastructure/fields/NumberInput';
import {FormikProps} from 'formik';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {Link, NavLink} from 'react-router-dom';
import {TextResource} from '../infrastructure/TextResource';
import SessionComponent from '../infrastructure/components/SessionComponent';
import {JString} from '../public/JString';
import {BaseInputProps, FieldHandle} from '../infrastructure/fields/field-helpers';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

interface Props extends AppContextProps {
}

interface State {
    cabinetsResponse: AppointmentCabinetsResponse | undefined;
    cabinetsLoading: boolean;
    doctorResponse: AppointmentDoctorsResponse | undefined;
    doctorLoading: boolean;
    daysResponse: AppointmentDaysResponse | undefined;
    daysLoading: boolean;
    hoursResponse: AppointmentHoursResponse | undefined;
    hoursLoading: boolean;

    makeAppointmentResult: Result<MakeAppointmentResponse> | undefined;
    loading: boolean;

    fakeCode: number;
    isVerified: boolean;
}

const initialState: State = {
    cabinetsResponse: undefined,
    cabinetsLoading: false,
    doctorResponse: undefined,
    doctorLoading: false,
    daysResponse: undefined,
    daysLoading: false,
    hoursResponse: undefined,
    hoursLoading: false,

    loading: false,
    makeAppointmentResult: undefined,

    fakeCode: 0,
    isVerified: false,
};

export class MakeAppointment extends BaseComponent<Props, State> {

    state: State = initialState;
    formHandle: FormikProps<any> | undefined = undefined;
    doctorIDRef: FieldHandle<BaseInputProps<any>> | undefined;

    async initForm() {

        await this.setStateAsync({fakeCode: random(1000, 9999)});

        await this.fetchCabinets();

        const {server, actions, commonService} = this.props.context;

        if (commonService.getPatientID()) {

            const patientInformationResult = await server.patientOwnInformation({});

            if (!patientInformationResult.success) {
                actions.errors.setErrorMessages(patientInformationResult.errorMessages);
                return;
            }

            if (this.formHandle) {
                this.formHandle.setFieldValue('patientName', patientInformationResult.payload.item.patientFullName);
                this.formHandle.setFieldValue('patientMobile', patientInformationResult.payload.item.patientMobile);
                this.formHandle.setFieldValue('patientIdentNumber',
                                                              patientInformationResult.payload.item.patientIdentNumber);
            }
        }
    }

    resetForm = async () => {
        await this.setStateAsync(initialState);
        await this.initForm();
    };

    async componentDidMountAsync() {
        await this.initForm();
    }

    async fetchCabinets(): Promise<any> {
        const {server, actions} = this.props.context;

        await this.setStateAsync({cabinetsLoading: true});

        const result = await server.appointmentCabinets({});

        if (result.success) {
            await this.setStateAsync({cabinetsResponse: result.payload, cabinetsLoading: false});
        } else {
            actions.errors.setErrorMessages(result.errorMessages);
            await this.setStateAsync({cabinetsLoading: false});
        }
    }

    getCabinets(): SelectItem[] {

        if (!this.state.cabinetsResponse) {
            return [];
        }

        return this.state.cabinetsResponse.items.map((x) => ({
            label: x.cabinetFullName,
            value: x.cabinetID,
        }));
    }

    async fetchDoctors(cabinetID: number): Promise<any> {

        const {server, actions} = this.props.context;

        await this.setStateAsync({doctorLoading: true});

        const result = await server.appointmentDoctors({cabinetID});

        if (result.success) {
            await this.setStateAsync({doctorResponse: result.payload, doctorLoading: false});
            if (result.payload.items.length === 1) {
                if (this.doctorIDRef) {
                    this.doctorIDRef.onChange(result.payload.items[0].doctorID);
                }
            }
        } else {
            actions.errors.setErrorMessages(result.errorMessages);
            await this.setStateAsync({doctorLoading: false});
        }
    }

    getDoctors(): SelectItem[] {

        if (!this.state.doctorResponse) {
            return [];
        }

        return this.state.doctorResponse.items.map((x) => ({
            label: x.doctorName,
            value: x.doctorID,
        }));
    }

    async fetchDays(cabinetID: number, doctorID: number): Promise<any> {

        const {server, actions} = this.props.context;

        await this.setStateAsync({daysLoading: true});

        const result = await server.appointmentDays({cabinetID, doctorID});

        if (result.success) {
            await this.setStateAsync({daysResponse: result.payload, daysLoading: false});
        } else {
            actions.errors.setErrorMessages(result.errorMessages);
            await this.setStateAsync({daysLoading: false});
        }
    }

    getDays(): Date[] {

        const response = this.state.daysResponse;

        if (!response) {
            return [];
        }

        return response.items;
    }

    async fetchHours(cabinetID: number, doctorID: number, date: Date): Promise<any> {

        const {server, actions} = this.props.context;

        await this.setStateAsync({hoursLoading: true});

        const result = await server.appointmentHours({cabinetID, doctorID, date});

        if (result.success) {
            await this.setStateAsync({hoursResponse: result.payload, hoursLoading: false});
        } else {
            actions.errors.setErrorMessages(result.errorMessages);
            await this.setStateAsync({hoursLoading: false});
        }
    }

    getHours(): SelectItem[] {

        const response = this.state.hoursResponse;

        if (!response) {
            return [];
        }

        return response.items.map((x) => ({label: format(x, 'HH:mm'), value: x}));
    }

    dateEquals = (x: Date, y: Date) => {
        return x.getFullYear() === y.getFullYear()
            && x.getMonth() === y.getMonth()
            && x.getDate() === y.getDate();
    };

    validateFakeCode = (val: number): string[] => {

        if (val !== this.state.fakeCode) {
            return ['Моля, въведете кода за сигурност.'];
        }

        return [];
    };

    onSubmit = async (item: AppointmentDto) => {
        if (!this.state.isVerified) {
            return;
        }

        const {server} = this.props.context;

        await this.setStateAsync({loading: true});

        const result = await server.makeAppointment({item});

        await this.setStateAsync({makeAppointmentResult: result, loading: false});
    };

    isPatientLoggedIn = () => {
        const {commonService} = this.props.context;
        return !!commonService.getPatientID();
    };

    // Дали е разрешена формата за регистрация на пациенти
    isEnablePatientRegistrationForm = () => {
        const {commonService} = this.props.context;
        return commonService.isFeatureEnabled('PatientRegisterPage');
    };

    getDoctorLabel = () => {
        const {commonService} = this.props.context;
        return commonService.getTextResource(25);
    };

    isShowLabelUpperTab = () => {
        const {commonService} = this.props.context;
        return !JString.isNullOrWhiteSpace(commonService.getTextResource(26));
    };

    render() {
        const cabinets = this.getCabinets();
        const doctors = this.getDoctors();
        const days = this.getDays();
        const hours = this.getHours();

        const {makeAppointmentResult, loading} = this.state;
        const {commonService, settingsService} = this.props.context;
        const settings = settingsService.getSettings();

        const validations = serverValidations.appointmentDto;

        const patientIdentNumberValidations = [
            ...validations.patientIdentNumber,
            ...(settings.requireEgnForAppointments ? [required('Моля, въведете ЕГН/ЛНЧ.')] : []),
        ];

        const enableFakeCode = !commonService.getDoctorID() && settings.enableFakeCaptchaForAppointments;

        return <div className="appointment-page">

            <div>
                <GoogleReCaptcha onVerify={() => this.setState({isVerified: true})}/>
            </div>

            <DocumentTitle title="Записване на час"/>
            {/* Когато успешно си запишеш час */}
            {makeAppointmentResult && makeAppointmentResult.success && <div>
                <div className="card">
                    <div className="card-header green white-text">Вашият час беше записан успешно.</div>
                    <div className="card-body">
                        <div className="text-center">{makeAppointmentResult.payload.message}</div>
                        <div className="text-center">
                            <SessionComponent publicOnly feature="PatientRegisterPage">
                                <Link to="/register"
                                      className="btn btn-md btn-primary">Продължи с попълването на Вашите
                                    данни</Link>
                            </SessionComponent>
                            <button className="btn btn-md btn-default"
                                    onClick={this.resetForm}>
                                Запиши нов
                            </button>

                            <Link to="/" className="btn btn-md btn-primary">Начало</Link>
                        </div>
                    </div>
                </div>
            </div>}
            {/* форма за записване на час */}
            {(!makeAppointmentResult || !makeAppointmentResult.success) && <CustomForm
                forwardedRef={(instance: any) => this.formHandle = instance}
                onSubmit={this.onSubmit}
                render={({values, setFieldValue, setFieldTouched, isSubmitting}) => <div className="card">
                        <div className="card-header blue white-text">Форма за запазване на час</div>
                        {!this.isPatientLoggedIn() && this.isEnablePatientRegistrationForm() &&
                            <div>
                                <div><p className="card-body font-weight-normal text-justify">
                                    Моля, влезте във Вашия профил! Това, ще Ви даде предимство за по-бързо
                                    обслужване при самото посещение.
                                </p></div>
                                <div className="button-group">
                                    <NavLink className="btn btn-default" to="">имам профил</NavLink>
                                    <NavLink className="btn btn-secondary" to="/register">нямам профил</NavLink>
                                </div>
                            </div>
                        }
                        <div className="card-body">
                            {this.isShowLabelUpperTab() && <TextResource injectHtml resourceId={26}/>}
                            <SelectField
                                label="Кабинет"
                                name="cabinetID"
                                items={cabinets}
                                isLoading={this.state.cabinetsLoading}
                                onChange={async (cabinetID: number | undefined) => {
                                    if (!cabinetID) {
                                        return;
                                    }

                                    await this.setStateAsync({
                                        doctorResponse: undefined,
                                        daysResponse: undefined,
                                        hoursResponse: undefined,
                                    });

                                    setFieldValue('doctorID', undefined);
                                    setFieldTouched('doctorID', false);
                                    setFieldValue('date', undefined);
                                    setFieldTouched('date', false);
                                    setFieldValue('dateTime', undefined);
                                    setFieldTouched('dateTime', false);

                                    await this.fetchDoctors(cabinetID);
                                }}
                                validation={validations.cabinetID}
                            />

                            <SelectField
                                label={this.getDoctorLabel()}
                                name="doctorID"
                                items={doctors}
                                isLoading={this.state.doctorLoading}
                                defaultValueOnSelectedItemRemoval
                                readonly={!values.cabinetID}
                                searchable={false}
                                customRef={(x) => this.doctorIDRef = x}
                                onChange={async (doctorID: number | undefined) => {
                                    if (!doctorID) {
                                        return;
                                    }

                                    await this.setStateAsync({
                                        daysResponse: undefined,
                                        hoursResponse: undefined,
                                    });

                                    setFieldValue('date', undefined);
                                    setFieldTouched('date', false);
                                    setFieldValue('dateTime', undefined);
                                    setFieldTouched('dateTime', false);

                                    const cabinetID = values.cabinetID as (number | undefined);

                                    if (!cabinetID) {
                                        throw new Error('Trying to fetch days but cabinetID is falsy.');
                                    }

                                    if (!doctorID) {
                                        throw new Error('Trying to fetch days but doctorID is falsy.');
                                    }

                                    await this.fetchDays(cabinetID, doctorID);
                                }}
                                validation={validations.doctorID}
                            />

                            <div className="row">
                                <div className="col-sm-6">
                                    <DateField
                                        label="Дата"
                                        name="date"
                                        readonly={!days.length}
                                        nullable
                                        disabledDays={(x) => {
                                            for (const y of days) {
                                                if (this.dateEquals(x, y)) {
                                                    return false;
                                                }
                                            }
                                            return true;
                                        }}
                                        onChange={async (date: Date | null) => {
                                            if (!date) {
                                                return;
                                            }

                                            await this.setStateAsync({
                                                hoursResponse: undefined,
                                            });

                                            setFieldValue('dateTime', undefined);
                                            setFieldTouched('dateTime', false);

                                            const cabinetID = values.cabinetID as (number | undefined);
                                            const doctorID = values.doctorID as (number | undefined);

                                            if (!cabinetID) {
                                                throw new Error('Trying to fetch days but cabinetID is falsy.');
                                            }

                                            if (!doctorID) {
                                                throw new Error('Trying to fetch days but doctorID is falsy.');
                                            }

                                            if (!date) {
                                                throw new Error('Trying to fetch days but date is falsy.');
                                            }

                                            await this.fetchHours(cabinetID, doctorID, date);
                                        }}
                                        validation={validations.dateTime}
                                    />

                                    {this.state.daysResponse && !days.length &&
                                        <div style={{textAlign: 'center', color: 'red'}}>Няма свободни дати.</div>}

                                </div>
                                <div className="col-sm-6">
                                    <SelectField
                                        label="Час"
                                        name="dateTime"
                                        items={hours}
                                        defaultValueOnSelectedItemRemoval
                                        readonly={!hours.length}
                                        searchable={false}
                                        validation={validations.dateTime}
                                    />

                                    {this.state.hoursResponse && !hours.length &&
                                        <div style={{textAlign: 'center', color: 'red'}}>Няма свободни
                                            часове.</div>}

                                </div>
                            </div>

                            <TextField
                                label="Име"
                                name="patientName"
                                readonly={this.isPatientLoggedIn()}
                                validation={validations.patientName}
                            />

                            <TextField
                                label="Мобилен"
                                name="patientMobile"
                                readonly={this.isPatientLoggedIn()}
                                // validation={validations.patientMobile}
                            />

                            <TextField
                                label="ЕГН/ЛНЧ"
                                name="patientIdentNumber"
                                readonly={this.isPatientLoggedIn()}
                                validation={patientIdentNumberValidations}
                            />

                            <TextField
                              label="Имейл"
                              name="patientEmail"
                              readonly={this.isPatientLoggedIn()}
                            />

                            {settings.appoShowAddress && <TextField
                                label="Адрес"
                                name="address"
                                validation={validations.address}
                            />}
                            <TextAreaField
                                label="Бележка"
                                name="note"
                                validation={validations.note}
                                inputStyle={{height: '3rem'}}
                            />

                            {enableFakeCode && <div className="fake-code-container row">
                                <div className="fake-code-input col-sm-8">
                                    <NumberField
                                        label="Код за сигурност"
                                        name="fakeCode"
                                        icon="lock"
                                        nullable
                                        validation={this.validateFakeCode}
                                    />
                                </div>
                                <div className="fake-code-label col-sm-4">{this.state.fakeCode}</div>

                            </div>}

                            {loading && <LoadingIndicator/>}

                            <div className="text-center">
                                <button type="submit" className="btn btn-md btn-default" disabled={isSubmitting}>
                                    Запази си час
                                </button>
                            </div>

                            {makeAppointmentResult && !makeAppointmentResult.success && <Fragment>
                                <ErrorMessages errors={makeAppointmentResult.errorMessages}/>
                                <hr/>
                            </Fragment>}
                        </div>
                    </div>}/>}

            <div className="card"
                 style={{
                     marginTop: '1rem',
                     padding: '1rem',
                     color: 'white',
                     backgroundColor: '#9954bb',
                 }}>
                <div className="card-body">
                    <TextResource injectHtml resourceId={1}/>
                </div>
            </div>
        </div>;
    }
}
