import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {TextResource} from './infrastructure/TextResource';
import {menuItems} from './infrastructure/global.state';

import './Home.scss';
import {ReduxState} from './infrastructure/redux/store';
import {useAppContext} from './infrastructure/custom-hooks';
import {shouldRender} from './infrastructure/render-permissions';
import SessionComponent from './infrastructure/components/SessionComponent';
import {Link} from 'react-router-dom';
import {NavLinkItem} from './infrastructure/layout/NavDropdown';

export const Home = () => {

  const session = useSelector((x: ReduxState) => x.session);
  const cache = useSelector((x: ReduxState) => x.cache);
  const context = useAppContext();

  const [menuItemSigning, setMenuItemSigning] = useState<NavLinkItem>();
  const [isTabletUser, setIsTabletUser] = useState<boolean>(false);

  useEffect(() => {
    if (!session.isLoggedIn) {
      context.actions.router.routerPush('/login');
    }

    setMenuItemSigning(menuItems.find((x) => x.link === '/doctor/document-signing'));
    setIsTabletUser(!!session.userPrivileges?.includes(60));
  }, []);

  return (
    <div className="home-page legacy-frame">
      <div className="home-page__resource-top">
        <TextResource injectHtml resourceId={8}/>
      </div>

      <SessionComponent role={'doctor'}>
        <div className="container-fluid card text-center">
          <div className="row justify-content-center">
            {isTabletUser && menuItemSigning ?
              (<Link
                key={menuItemSigning.name} to={menuItemSigning.link}
                className={`card col-sm-4 ${menuItemSigning.color} text-white m-1`}
                style={{padding: 1.5 + 'rem'}}>
                < i className={`fa ${menuItemSigning.icon} fa-3x`}/>
                {menuItemSigning.name}
              </Link>) :
              (<>
                {menuItems.filter((x) => shouldRender(x, session, cache)).map((i) => (
                  <Link
                    key={i.name} to={i.link} className={`card col-sm-4 ${i.color} text-white m-1`}
                    style={{padding: 1.5 + 'rem'}}>
                    < i className={`fa ${i.icon} fa-3x`}/>
                    {i.name}
                  </Link>
                ))
                })
              </>)
            }
          </div>
        </div>
      </SessionComponent>
      <SessionComponent role={'patient'}>
        <div className="container-fluid card text-center">
          <div className="row justify-content-center">
            {menuItems.filter((x) => shouldRender(x, session, cache)).map((i) => (
              <Link
                key={i.name} to={i.link} className={`card col-sm-4 ${i.color} text-white m-1`}
                style={{padding: 1.5 + 'rem'}}>
                < i
                  className={`fa ${i.icon} fa-3x`}/>{i.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="home-page__resource-bottom">
          <TextResource injectHtml resourceId={21}/>
        </div>
      </SessionComponent>
    </div>
  );
};
